import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import $f from "../src";
import ScheduleEvent from "../themes/hapeiron/templates/events/scheduleEvent";
import ScheduleTest from "../themes/hapeiron/templates/events/scheduleTest";
import ScheduleTraining from "../themes/hapeiron/templates/events/scheduleTraining";
import InviteContactsOptions from "../themes/hapeiron/templates/inviteContactsOptions";
import UserProfile from "../themes/hapeiron/templates/userProfile";
import parse from "html-react-parser";
import Module from "../types/module/module";
import Actions from "./actions/actions";
import $c from "./functions";

const Modals = {
  participants: {
    removeUser: (args) => {
      const title = "Remove user?";

      const Body = (
        <p>
          Are you sure you want to remove user{" "}
          <strong>"{args.userName}"</strong>
          <br></br>
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.participants.removeUser(
                args.type,
                args.sectionId,
                args.participantId,
                args.participantsProperty
              );
            }}
            className="btn btn-sm btn-danger"
          >
            Remove
          </button>,
        ],
        body: Body,
      });
    },
    addParticipants: (args) => {
      let property = {};
      property.configuration = {
        module: "ParticipantTable",
        view: "detail",
        replace: {
          id: args.props?.data?._id,
        },
        api: args.api ? args.api : "view",
        inputData: args.inputData,
      };

      const ref = React.createRef();

      const Body = (
        <>
          <Module property={property} key={$f.key()} ref={ref}></Module>
          <span>{args.subtitle}</span>
        </>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        overflowY: "unset",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Add people"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.participants.addParticipant(
                args.actionType,
                args.actionId,
                args.actionSection,
                ref
              );
            }}
            className="btn btn-sm btn-purple"
          >
            {args.btnTitle ? args.btnTitle : "Add"}
          </button>,
        ],
        body: Body,
      });
    },
    addAll: (args) => {
      let property = {};
      property.configuration = {
        module: "ParticipantTable",
        view: "detailAll",
        replace: {
          id: args.props?.data?._id,
        },
        api: args.api ? args.api : "view",
        inputData: args.inputData,
      };

      const ref = React.createRef();

      const Body = (
        <>
          <Module property={property} key={$f.key()} ref={ref}></Module>
          <span>{args.subtitle}</span>
        </>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        overflowY: "unset",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Add people"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.participants.addParticipant(
                args.actionType,
                args.actionId,
                args.actionSection,
                ref
              );
            }}
            className="btn btn-sm btn-purple"
          >
            {args.btnTitle ? args.btnTitle : "Add"}
          </button>,
        ],
        body: Body,
      });
    },
    addAllAndExternals: (args) => {
      let property = {};
      property.configuration = {
        module: "ParticipantTable",
        view: "detailAllAndExternals",
        replace: {
          id: args.props?.data?._id,
        },
        api: args.api ? args.api : "view",
        inputData: args.inputData,
      };

      const ref = React.createRef();

      const Body = (
        <>
          <Module property={property} key={$f.key()} ref={ref}></Module>
          <span>{args.subtitle}</span>
        </>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        overflowY: "unset",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Add people"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.participants.addParticipant(
                args.actionType,
                args.actionId,
                args.actionSection,
                ref
              );
            }}
            className="btn btn-sm btn-purple"
          >
            {args.btnTitle ? args.btnTitle : "Add"}
          </button>,
        ],
        body: Body,
      });
    },
  },
  contacts: {
    inviteContacts: () => {
      const ref = React.createRef();

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        extra: null,
        title: (
          <div className=" header-ellipsis">
            <h2 className="display-3-no-line-height">Invite people</h2>
            <h5 className="display-5-no-bold color-grey">
              Select one of the invitation methods.
            </h5>
          </div>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (ref.current.state.checkedOption) {
                case "manually":
                  Modals.contacts.inviteManually({
                    extraButton: (
                      <button
                        type="button"
                        onClick={() => {
                          Modals.contacts.inviteContacts();
                        }}
                        className="btn btn-sm btn-white me-auto"
                      >
                        <i className="fas fa-chevron-left me-2"></i> Back
                      </button>
                    ),
                  });
                  break;

                case "multiple":
                  Modals.contacts.inviteMultiple({
                    extraButton: (
                      <button
                        type="button"
                        onClick={() => {
                          Modals.contacts.inviteContacts();
                        }}
                        className="btn btn-sm btn-white me-auto"
                      >
                        <i className="fas fa-chevron-left me-2"></i> Back
                      </button>
                    ),
                  });
                  break;

                case "directly":
                  Modals.contacts.inviteDirectly({
                    extraButton: (
                      <button
                        type="button"
                        onClick={() => {
                          Modals.contacts.inviteContacts();
                        }}
                        className="btn btn-sm btn-white me-auto"
                      >
                        <i className="fas fa-chevron-left me-2"></i> Back
                      </button>
                    ),
                  });
                  break;
              }
            }}
            className="btn btn-sm btn-purple scheduleEventNextBtn disabled"
          >
            Next
          </button>,
        ],
        body: (
          <InviteContactsOptions
            ref={ref}
            key={$f.key()}
          ></InviteContactsOptions>
        ),
      });
    },
    inviteManually: (args) => {
      const ref = React.createRef();

      let property = {};
      property.configuration = {
        module: "Contacts",
        view: "inviteManually",
      };

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        extra: null,
        title: (
          <div className=" ">
            <h2 className="display-3-no-line-height">
              Invite one or more users
            </h2>
            <h5 className="display-5-no-bold color-grey">
              Invite one or more users. A registration link will be sent to the
              invitees through an email.
            </h5>
          </div>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.contacts.inviteManually(ref);
            }}
            className="btn btn-sm btn-purple"
          >
            Send invite
          </button>,
        ],
        body: Body,
      });
    },
    inviteMultiple: (args) => {
      const ref = React.createRef();

      let property = {};
      property.configuration = {
        module: "Contacts",
        view: "inviteMultiple",
      };

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        extra: null,
        title: (
          <div className=" ">
            <h2 className="display-3-no-line-height">Invite multiple users</h2>
            <h5 className="display-5-no-bold color-grey">
              Upload an excel file to invite multiple users.{" "}
              <span
                className="color-purple strong"
                role="button"
                onClick={() => {
                  $c.downloadFile(
                    "/auth/downloadUserTemplate/false?",
                    "Contacts_Template"
                  );
                }}
              >
                Download
              </span>{" "}
              the sample excel template to add your contacts.<br></br> A
              registration link will be sent to the invitees through an email.
            </h5>
          </div>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.contacts.inviteMultiple(ref);
            }}
            className="btn btn-sm btn-purple scheduleEventNextBtn disabled"
          >
            Send invite
          </button>,
        ],
        body: Body,
      });
    },
    inviteDirectly: (args) => {
      const ref = React.createRef();

      let property = {};
      property.configuration = {
        module: "Contacts",
        view: "inviteDirectly",
      };

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        extra: null,
        title: (
          <div className=" ">
            <h2 className="display-3-no-line-height">
              Register users directly
            </h2>
            <h5 className="display-5-no-bold color-grey">
              Upload an excel file to register your users directly.{" "}
              <span
                className="color-purple strong"
                role="button"
                onClick={() => {
                  $c.downloadFile(
                    "/auth/downloadUserTemplate/true?",
                    "Contacts_Register_Template"
                  );
                }}
              >
                Download
              </span>{" "}
              the sample excel template to add your users.
            </h5>
          </div>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.contacts.inviteDirectly(ref);
            }}
            className="btn btn-sm btn-purple scheduleEventNextBtn disabled"
          >
            Register users
          </button>,
        ],
        body: Body,
      });
    },
    activateDeactivateContact: (args) => {
      const title = "Activate user?";

      const Body = (
        <p>
          Are you sure you want to{" "}
          {args.action == "activate" ? "activate" : "deactivate"} user{" "}
          <strong>"{args.userName}"</strong>
          <br></br>
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.action == "activate" ? "Activate" : "Deactivate"} user
          </h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "activate":
                  Actions.contacts.activateUser(args._id);
                  break;

                case "deactivate":
                  Actions.contacts.deactivateUser(args._id);
                  break;
              }
            }}
            className={"btn btn-sm ".concat(
              args.action == "activate" ? "btn-purple" : "btn-danger"
            )}
          >
            {args.action == "activate" ? "Activate" : "Deactivate"}
          </button>,
        ],
        body: Body,
      });
    },
    deleteContact: (args) => {
      const title = "Delete user?";

      const Body = (
        <p>
          Are you sure you want to delete user{" "}
          <strong>"{args.userName}"</strong>
          <br></br>
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.contacts.delete(args._id);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
    resendInvitation: (args) => {
      const title = "Resend invitation?";

      const Body = (
        <p>
          Are you sure you want to resend invitation for user{" "}
          <strong>"{args.userName}"</strong>
          <br></br>
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.contacts.resendInvitation(args.email);
            }}
            className="btn btn-sm btn-purple"
          >
            Resend invitation
          </button>,
        ],
        body: Body,
      });
    },
    revokeInvitation: (args) => {
      const title = "Revoke invitation?";

      const Body = (
        <p>
          Are you sure you want to revoke invitation for user{" "}
          <strong>"{args.userName}"</strong>
          <br></br>
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.contacts.revokeInvitation(args.email);
            }}
            className="btn btn-sm btn-danger"
          >
            Revoke invitation
          </button>,
        ],
        body: Body,
      });
    },
    userProfile: (args) => {
      const Body = (
        <UserProfile
          key={$f.key()}
          userId={args.userId}
          bioOnly={args.bioOnly}
        ></UserProfile>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: "",
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Close
          </button>,
        ],
        body: Body,
      });
    },
  },
  files: {
    newFolder: (args) => {
      let property = {};
      property.configuration = {
        module: "CommonFilesModule",
        view: "createFolder",
        inputData: args.inputData,
      };

      const ref = React.createRef();
      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            Create Folder
          </h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.files.createFolder(ref);
            }}
            className="btn btn-sm btn-purple"
          >
            Create Folder
          </button>,
        ],
        body: Body,
      });
    },
    editFile: (args) => {
      let property = {};
      property.configuration = {
        module: "CommonFilesModule",
        view: "fileDetails",
        replace: {
          id: args.props?.data._id,
        },
        api: args.api ? args.api : "view",
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      const footer = [
        args.extraButton,
        <button
          type="button"
          onClick={() => {
            window["primaryModal"].close();
          }}
          className="btn btn-sm btn-white"
        >
          Cancel
        </button>,
      ];

      if (!args.viewOnly) {
        footer.push(
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "update":
                  Actions.files.update(ref);
                  break;

                case "update-agenda-file":
                  Actions.files.updateAgendaFile(ref);
                  break;
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>
        );
      }

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Edit file"}
          </h2>
        ),
        footer: footer,
        body: Body,
      });
    },
    editFolder: (args) => {
      let property = {};
      property.configuration = {
        module: "CommonFilesModule",
        view: "folderDetails",
        replace: {
          id: args.props?.data._id,
        },
        api: args.api ? args.api : "view",
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Edit folder"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "update":
                  Actions.files.update(ref);
                  break;
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Update
          </button>,
        ],
        body: Body,
      });
    },
    deleteFolder: (args) => {
      const title = "Delete training event?";

      const Body = (
        <p>
          Are you sure you want to delete{" "}
          <strong>"{args.props.data.title}"</strong> folder?<br></br>
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Delete folder"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.files.deleteFolder(args.props.data._id);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
    deleteFile: (args) => {
      const title = "Delete training event?";

      const Body = (
        <p>
          Are you sure you want to delete{" "}
          <strong>"{args.props.data.title}"</strong> file?<br></br>
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Delete file"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "delete-agenda-file":
                  Actions.files.deleteAgendaFile(
                    args.props.data._id,
                    args.type,
                    args.sectionId,
                    args.eventId
                  );
                  break;

                default:
                  Actions.files.deleteFile(args.props.data._id);
                  break;
              }
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
  },
  events: {
    askQuestion: (args) => {
      const title = "Ask question";

      const ref = React.createRef();

      let property = {};
      property.configuration = {
        module: "ConferencesQuestions",
        view: "detail",
      };

      const Body = (
        <div>
          <p>
            Questions will be viewed by the host only. Questions are answered
            during the presentation orally.
          </p>
          <Module property={property} key={$f.key()} ref={ref}></Module>
        </div>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.conferences.askQuestion(ref, args);
            }}
            className="btn btn-sm btn-purple"
          >
            Send question
          </button>,
        ],
        body: Body,
      });
    },
    editPoll: (args) => {
      let property = {};
      property.configuration = {
        module: "ConferencesPolls",
        view: "detail",
        replace: args.replace ? args.replace : {},
        api: args.api ? args.api : "view",
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Edit poll"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "update":
                  Actions.polls.update(ref, args);
                  break;
                case "create":
                  Actions.polls.create(ref, args);
                  break;
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    deletePoll: (args) => {
      const title = "Delete poll?";

      const postData = {
        eventId: args.props.data._id,
      };

      const Body = (
        <p>
          Are you sure you want to delete{" "}
          <strong>"{args.props.data.title}"</strong> poll?
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.polls.delete(args);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
    startPoll: (args) => {
      const title = "Start poll?";

      const Body = (
        <p>
          Are you sure you want to start{" "}
          <strong>"{args.props.data.title}"</strong> poll?<br></br>
          You won't be able to edit poll after starting it!
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.polls.start(args);
            }}
            className="btn btn-sm btn-purple"
          >
            Start
          </button>,
        ],
        body: Body,
      });
    },
    endPoll: (args) => {
      const title = "End poll?";

      const Body = (
        <p>
          Are you sure you want to end{" "}
          <strong>"{args.props.data.title}"</strong> poll?<br></br>
          After ending the poll, participants won't be able to submit any more
          votes. You and all attendees will view the poll results.
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.polls.end(args);
            }}
            className="btn btn-sm btn-danger"
          >
            End poll
          </button>,
        ],
        body: Body,
      });
    },
    editTraining: (args) => {
      let property = {};
      property.configuration = {
        module: "Trainings",
        view: "detail",
        replace: {
          id: args.props?.data?._id,
        },
        api: args.api ? args.api : "view",
        inputData: args.inputData,
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Edit training"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "update":
                  Actions.trainings.update(ref);
                  break;
                case "create":
                  Actions.trainings.create(ref);
                  break;
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    deleteTraining: (args) => {
      const title = "Delete training event?";

      const Body = (
        <p>
          Are you sure you want to delete{" "}
          <strong>"{args.props.data.title}"</strong> training?<br></br>
          <br></br>
          This action will delete:<br></br>- All training events (tests, video
          conferences, video presentations, readings).<br></br>- All test
          results if any
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.trainings.delete(args.props.data._id);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
    editMeeting: (args) => {
      let property = {};
      property.configuration = {
        module: "Meetings",
        view: "detail",
        replace: {
          id: args.props?.data._id,
        },
        api: args.api ? args.api : "view",
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Edit meeting"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "update":
                  Actions.meetings.update(ref);
                  break;
                case "create":
                  Actions.meetings.create(ref);
                  break;
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    deleteMeeting: (args) => {
      const title = "Delete meeting event?";

      const postData = {
        eventId: args.props.data._id,
      };

      const Body = (
        <p>
          Are you sure you want to delete{" "}
          <strong>"{args.props.data.title}"</strong> meeting?
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.meetings.delete(args.props.data._id, args.callback);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
    editConference: (args) => {
      let property = {};
      property.configuration = {
        module: "Conferences",
        view: "detail",
        replace: {
          id: args.props?.data._id,
        },
        api: args.api ? args.api : "view",
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Edit conference"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "update":
                  Actions.conferences.update(ref);
                  break;
                case "create":
                  Actions.conferences.create(ref);
                  break;
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    editPartner: (args) => {
      let property = {};
      property.configuration = {
        module: "ConferencesPartners",
        view: "detail",
        replace: {
          id: args.props?.data._id,
        },
        api: "view",
      };
      const title = args.title ? args.title : "Edit partner";

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.conferencePartners.update(ref);
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    addPartner: (args) => {
      let property = {};
      property.configuration = {
        module: "ConferencesPartners",
        view: "detail",
        replace: {},
        api: "new",
      };

      const ref = React.createRef();

      const Body = (
        <>
          <Module property={property} key={$f.key()} ref={ref}></Module>
          <span>{args.subtitle}</span>
        </>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Add partner"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.conferencePartners.create(ref);
            }}
            className="btn btn-sm btn-purple"
          >
            Add
          </button>,
        ],
        body: Body,
      });
    },
    openPartner: (args) => {
      const ref = React.createRef();

      const Body = (
        <div className="row">
          <div className="col-md-3">
            <img
              src={$c.agendaFileImageURL(args.logo._id)}
              className="img-thumbnail border-0"
              title={args.title}
            ></img>
          </div>
          <div className="col-md-9">
            <h3 className="display-3">{args.title}</h3>
            <p>{args.description ? parse(args.description) : ""}</p>
            {args.link ? (
              <a href={args.link} target="_blank" className="text-primary">
                {args.link}
              </a>
            ) : (
              ""
            )}
          </div>
        </div>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: <p></p>,
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Close
          </button>,
        ],
        body: Body,
      });
    },
    openSpeaker: (args) => {
      const speaker = args.speaker;
      const data = args.data;

      let speakerSessions = [];

      data.days?.forEach((itm, idx) => {
        itm.sessions.forEach((sitm, sidx) => {
          let isSpeaker = false;

          sitm.speakers.forEach((spitm, spidx) => {
            if (spitm._id == speaker._id) {
              isSpeaker = true;
            }
          });

          if (isSpeaker) {
            speakerSessions.push(
              <div className="border-top mt-2 pt-2 pb-2 ">
                <span className=" d-block  ">
                  <strong>{sitm.title}</strong>
                </span>
                <span className="d-block mt-2 color-grey">
                  {moment(itm.date).format("ddd DD MMM YYYY")}
                  {" | "}
                  {moment(sitm.fromRaw).format("HH:mm")}
                  {" - "}
                  {moment(sitm.toRaw).format("HH:mm")}
                </span>
              </div>
            );
          }
        });
      });

      const Body = (
        <div className="container p-5">
          <div className="row">
            <div className="col-md-4">
              <h3 className="text-dark-blue text-strong mb-3">
                {speaker.firstName} {speaker.lastName}
              </h3>
              <span className="d-block">{speaker.jobTitle}</span>
              {speakerSessions}
            </div>
            <div className="col-md-8">
              {speaker.profileImage ? (
                <img
                  src={$c.agendaFileImageURL(speaker.profileImage)}
                  className="img-thumbnail border-0"
                  title={args.title}
                ></img>
              ) : (
                ""
              )}
              {speaker.biography ? parse(speaker.biography) : ""}
            </div>
          </div>
        </div>
      );

      window["primaryModal"].setState({
        show: true,
        size: "xl",
        title: <p></p>,
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Close
          </button>,
        ],
        body: Body,
      });
    },
    deletePartner: (args) => {
      const title = "Delete partner?";

      const Body = (
        <p>
          Are you sure you want to delete partner{" "}
          <strong>"{args.props.data.title}"</strong>?
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.conferencePartners.delete(args.props.data._id);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
    deleteConference: (args) => {
      const title = "Delete conference event?";

      const postData = {
        eventId: args.props.data._id,
      };

      const Body = (
        <p>
          Are you sure you want to delete{" "}
          <strong>"{args.props.data.title}"</strong> conference?
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.conferences.delete(args.props.data._id);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
    editTest: (args) => {
      let property = {};

      property.configuration = {
        module: "Tests",
        view: args.props?.view ? args.props.view : "detail",
        replace: {
          id: args.props?.data._id,
        },
        api: args.api ? args.api : "view",
        inputData: args.inputData,
      };

      const ref = React.createRef();

      console.log(property);

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Edit test"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "update":
                  Actions.tests.update(ref);
                  break;
                case "create":
                  Actions.tests.create(ref);
                  break;
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    deleteTest: (args) => {
      const title = "Delete test event?";

      const postData = {
        eventId: args.props.data._id,
      };

      const Body = (
        <p>
          Are you sure you want to delete{" "}
          <strong>"{args.props.data.title}"</strong> test?
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.tests.delete(args.props.data._id, args.callback);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
    duplicateTest: (args) => {
      const title = "Copy test?";

      const Body = (
        <p>
          Are you sure you want to create a copy of{" "}
          <strong>"{args.props.data.title}"</strong> test?
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.tests.duplicate(args.props.data);
            }}
            className="btn btn-sm btn-purple"
          >
            Duplicate
          </button>,
        ],
        body: Body,
      });
    },
    startContinueTest: (args) => {
      const title = "Start test?";

      const postData = {
        eventId: args.props.data._id,
      };

      const Body = (
        <p>
          Once you enter the test page the duration of the test will start a
          countdown.<br></br> Please be informed that if you navigate away from
          the test page:<br></br> - Your progress won't be lost (all answers
          will be saved), so you can resume the test at the same point<br></br>{" "}
          - Time will continue counting when you are away<br></br> - Your
          progress will be automatically submitted when the test duration ends
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              $f.fetch("/exam/user-exam", "POST", postData, {}, {}, (resp) => {
                let examId = resp.data.data[0].examEvent;

                window.location.replace("/test/".concat(examId));
              });
            }}
            className="btn btn-sm btn-purple"
          >
            Start
          </button>,
        ],
        body: Body,
      });
    },
    selectTestFromTemplate: (args) => {
      let property = {};

      property.configuration = {
        module: "TestTemplates",
        view: "selectBoxes",
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        extra: null,
        title: (
          <div className=" header-ellipsis">
            <h2 className="display-3-no-line-height">Schedule test</h2>
            <h5 className="display-5-no-bold color-grey">
              Select one of your test template
            </h5>
          </div>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              Modals.events.scheduleTest();
            }}
            className="btn btn-sm btn-white me-auto"
          >
            <i className="fas fa-chevron-left me-2"></i> Back
          </button>,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              const id = ref.current.refTemplate.current.state.checkedOption;

              Modals.events.editTest({
                action: "create",
                api: "fromTemplate",
                props: { data: { _id: id } },
                title: "Schedule test",
              });
            }}
            className="btn btn-sm btn-purple scheduleEventNextBtn disabled"
          >
            Next
          </button>,
        ],
        body: Body,
      });
    },
    scheduleTest: (args) => {
      const ref = React.createRef();

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        extra: null,
        title: (
          <div className=" header-ellipsis">
            <h2 className="display-3-no-line-height">Schedule event</h2>
            <h5 className="display-5-no-bold color-grey">
              Select one of the event categories
            </h5>
          </div>
        ),
        footer: [
          args?.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (ref.current.state.checkedOption) {
                case "new":
                  $f.getValue(Modals.events.editTest, {
                    title: "Schedule test",
                    api: "new",
                    action: "create",
                    extraButton: (
                      <button
                        type="button"
                        onClick={() => {
                          Modals.events.scheduleTest();
                        }}
                        className="btn btn-sm btn-white me-auto"
                      >
                        <i className="fas fa-chevron-left me-2"></i> Back
                      </button>
                    ),
                  });
                  break;

                case "template":
                  Modals.events.selectTestFromTemplate();
                  break;
              }
            }}
            className="btn btn-sm btn-purple scheduleEventNextBtn disabled"
          >
            Next
          </button>,
        ],
        body: <ScheduleTest ref={ref} key={$f.key()}></ScheduleTest>,
      });
    },
    scheduleEvent: () => {
      const ref = React.createRef();

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        extra: null,
        title: (
          <div className=" header-ellipsis">
            <h2 className="display-3-no-line-height">Schedule event</h2>
            <h5 className="display-5-no-bold color-grey">
              Select one of the event categories
            </h5>
          </div>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (ref.current.state.checkedOption) {
                case "test":
                  Modals.events.scheduleTest({
                    extraButton: (
                      <button
                        type="button"
                        onClick={() => {
                          Modals.events.scheduleEvent();
                        }}
                        className="btn btn-sm btn-white me-auto"
                      >
                        <i className="fas fa-chevron-left me-2"></i> Back
                      </button>
                    ),
                  });
                  break;

                case "training":
                  Modals.events.scheduleTraining({
                    extraButton: (
                      <button
                        type="button"
                        onClick={() => {
                          Modals.events.scheduleEvent();
                        }}
                        className="btn btn-sm btn-white me-auto"
                      >
                        <i className="fas fa-chevron-left me-2"></i> Back
                      </button>
                    ),
                  });
                  break;

                case "meeting":
                  Modals.events.editMeeting({
                    title: "Schedule meeting",
                    api: "new",
                    action: "create",
                    extraButton: (
                      <button
                        type="button"
                        onClick={() => {
                          Modals.events.scheduleEvent();
                        }}
                        className="btn btn-sm btn-white me-auto"
                      >
                        <i className="fas fa-chevron-left me-2"></i> Back
                      </button>
                    ),
                  });
                  break;

                case "conference":
                  Modals.events.editConference({
                    title: "Schedule conference",
                    api: "new",
                    action: "create",
                    extraButton: (
                      <button
                        type="button"
                        onClick={() => {
                          Modals.events.scheduleEvent();
                        }}
                        className="btn btn-sm btn-white me-auto"
                      >
                        <i className="fas fa-chevron-left me-2"></i> Back
                      </button>
                    ),
                  });
                  break;
              }
            }}
            className="btn btn-sm btn-purple scheduleEventNextBtn disabled"
          >
            Next
          </button>,
        ],
        body: <ScheduleEvent ref={ref} key={$f.key()}></ScheduleEvent>,
      });
    },
    calendarEvent: (event) => {
      let property = {};

      let type = event.type;
      let eventType = event.type;
      let prefix = "";
      let url = "";
      let title = event.title;

      let moreDetailsURL = "";

      switch (event.type) {
        case "meeting":
          eventType = "meeting";
          moreDetailsURL = "/event/meeting/".concat(event._id);
          break;

        case "stream":
          eventType = "conference";
          type = "stream";
          moreDetailsURL = "/event/conference/".concat(event._id);
          break;

        case "conference":
          type = "stream";
          eventType = "conference";
          moreDetailsURL = "/event/conference/".concat(event._id);
          if (event.training) {
            title = event.training.title;
            type = "liveclass";
            eventType = "live class";
            url = "/event/training/".concat(event.training._id);
            moreDetailsURL = "/event/training/".concat(
              event.training._id,
              "/module/",
              event.module._id
            );
          }

          break;

        case "exam":
          type = "exam";
          prefix = "event/";
          eventType = "test";
          moreDetailsURL = "/event/test/".concat(event._id);
          if (event.training) {
            type = "trainingexam";
            title = event.training.title;
            url = "/event/training/".concat(event.training._id);
            moreDetailsURL = "/event/training/".concat(
              event.training._id,
              "/module/",
              event.module._id,
              "/session/",
              event.session._id
            );
          }
          break;
      }

      property.configuration = {
        module: "Calendar",
        view: "eventModal",
        type: type,
        conferenceDayId: event.dayId,
        eventId: event._id,

        replace: {
          url: url
            ? url
            : "/".concat(prefix).concat(type.concat("/" + event._id)),
        },
      };

      const Body = <Module property={property} key={$f.key()}></Module>;

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <div className=" header-ellipsis">
            <h2 className="display-3">{title}</h2>
            <h5 className="display-5-no-bold color-grey">
              {$c.formatDateFromTo(event.from, event.to)}
            </h5>
          </div>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              let icsEvent = { ...event };

              icsEvent.url = url
                ? url
                : "/".concat(prefix).concat(type.concat("/" + event._id));

              icsEvent.eventType = eventType;

              $c.createICS(icsEvent);
            }}
            className="btn btn-sm btn-white me-auto"
          >
            <i className="far fa-calendar-alt me-2"></i> Add to calendar
          </button>,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <Link
            to={moreDetailsURL}
            onClick={() => {
              window["primaryModal"].setState({ show: false });
            }}
            className="btn btn-sm btn-purple"
          >
            More details
          </Link>,
        ],
        body: Body,
      });
    },
    editTestTemplate: (args) => {
      let property = {};

      property.configuration = {
        module: "TestTemplates",
        view: "detail",
        replace: {
          id: args.props?.data._id,
        },
        api: args.api ? args.api : "view",
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Edit test Template"}
          </h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "update":
                  Actions.testTemplates.update(ref);
                  break;
                case "create":
                  Actions.testTemplates.create(ref);
                  break;
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    deleteTestTemplate: (args) => {
      const title = "Delete test template?";

      const postData = {
        eventId: args.props.data._id,
      };

      const Body = (
        <p>
          Are you sure you want to delete{" "}
          <strong>"{args.props.data.title}"</strong> test template?
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.testTemplates.delete(args.props.data._id);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
    duplicateTestTemplate: (args) => {
      const title = "Copy test template?";

      const Body = (
        <p>
          Are you sure you want to create a copy of{" "}
          <strong>"{args.props.data.title}"</strong> test template?
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.testTemplates.duplicate(args.props.data);
            }}
            className="btn btn-sm btn-purple"
          >
            Duplicate
          </button>,
        ],
        body: Body,
      });
    },
    editTrainingTemplate: (args) => {
      let property = {};

      property.configuration = {
        module: "TrainingTemplates",
        view: "detail",
        replace: {
          id: args.props?.data._id,
        },
        api: args.api ? args.api : "view",
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Edit training Template"}
          </h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "update":
                  Actions.trainingTemplates.update(ref);
                  break;
                case "create":
                  Actions.trainingTemplates.create(ref);
                  break;
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    deleteTrainingTemplate: (args) => {
      const title = "Delete training template?";

      const postData = {
        eventId: args.props.data._id,
      };

      const Body = (
        <p>
          Are you sure you want to delete{" "}
          <strong>"{args.props.data.title}"</strong> training template?
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.trainingTemplates.delete(args.props.data._id);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
    duplicateTrainingTemplate: (args) => {
      const title = "Copy training template?";

      const Body = (
        <p>
          Are you sure you want to create a copy of{" "}
          <strong>"{args.props.data.title}"</strong> training template?
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.trainingTemplates.duplicate(args.props.data);
            }}
            className="btn btn-sm btn-purple"
          >
            Duplicate
          </button>,
        ],
        body: Body,
      });
    },
    selectTestFromTemplateForTraining: (args) => {
      let property = {};

      property.configuration = {
        module: "TestTemplates",
        view: "selectBoxes",
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["secondaryModal"].setState({
        show: true,
        size: "lg",
        extra: null,
        title: (
          <div className=" header-ellipsis">
            <h2 className="display-3-no-line-height">Select test</h2>
            <h5 className="display-5-no-bold color-grey">
              Select one of your test template
            </h5>
          </div>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["secondaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              const exam = ref.current.refTemplate.current.state.checkedValue;

              exam.fromTemplate = true;
              args.select(
                args.moduleIndex,
                args.sessionIndex,
                args.examIndex,
                exam
              );
              window["secondaryModal"].close();
            }}
            className="btn btn-sm btn-purple scheduleEventNextBtn disabled"
          >
            Select
          </button>,
        ],
        body: Body,
      });
    },
    editTestTemplateForTraining: (args) => {
      let property = {};

      property.configuration = {
        module: "Tests",
        view: "detailForTraining",
        inputData: args.inputData,
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["secondaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            Edit test Template
          </h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["secondaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={(e) => {
              const exam =
                Actions.trainingTemplates.fetchExamTemplateObject(ref);

              if (exam) {
                args.onSave(exam);
                window["secondaryModal"].close();
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    selectTrainingFromTemplate: (args) => {
      let property = {};

      property.configuration = {
        module: "TrainingTemplates",
        view: "selectBoxes",
      };

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        extra: null,
        title: (
          <div className=" header-ellipsis">
            <h2 className="display-3-no-line-height">Schedule training</h2>
            <h5 className="display-5-no-bold color-grey">
              Select one of your training template
            </h5>
          </div>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              Modals.events.scheduleTraining();
            }}
            className="btn btn-sm btn-white me-auto"
          >
            <i className="fas fa-chevron-left me-2"></i> Back
          </button>,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              const id = ref.current.refTemplate.current.state.checkedOption;

              Modals.events.editTraining({
                action: "create",
                api: "fromTemplate",
                props: { data: { _id: id } },
                title: "Schedule training",
                extraButton: (
                  <button
                    type="button"
                    onClick={() => {
                      Modals.events.selectTrainingFromTemplate();
                    }}
                    className="btn btn-sm btn-white me-auto"
                  >
                    <i className="fas fa-chevron-left me-2"></i> Back
                  </button>
                ),
              });
            }}
            className="btn btn-sm btn-purple scheduleEventNextBtn disabled"
          >
            Next
          </button>,
        ],
        body: Body,
      });
    },
    scheduleTraining: (args) => {
      const ref = React.createRef();

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        extra: null,
        title: (
          <div className=" header-ellipsis">
            <h2 className="display-3-no-line-height">Schedule training</h2>
            <h5 className="display-5-no-bold color-grey">
              Select a training template or schedule a new training.
            </h5>
          </div>
        ),
        footer: [
          args?.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (ref.current.state.checkedOption) {
                case "new":
                  $f.getValue(Modals.events.editTraining, {
                    title: "Schedule training",
                    api: "new",
                    action: "create",
                    extraButton: (
                      <button
                        type="button"
                        onClick={() => {
                          Modals.events.scheduleTraining();
                        }}
                        className="btn btn-sm btn-white me-auto"
                      >
                        <i className="fas fa-chevron-left me-2"></i> Back
                      </button>
                    ),
                  });
                  break;

                case "template":
                  Modals.events.selectTrainingFromTemplate({
                    extraButton: (
                      <button
                        type="button"
                        onClick={() => {
                          Modals.events.scheduleTraining();
                        }}
                        className="btn btn-sm btn-white me-auto"
                      >
                        <i className="fas fa-chevron-left me-2"></i> Back
                      </button>
                    ),
                  });
                  break;
              }
            }}
            className="btn btn-sm btn-purple scheduleEventNextBtn disabled"
          >
            Next
          </button>,
        ],
        body: <ScheduleTraining ref={ref} key={$f.key()}></ScheduleTraining>,
      });
    },
  },
  forms: {
    editForm: (args) => {
      let property = {};
      property.configuration = {
        module: "Forms",
        view: "detail",
        replace: {
          id: args.props?.data._id,
        },
        api: args.api ? args.api : "view",
      };

      const title = args.title ? args.title : "Edit form";

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              switch (args.action) {
                case "update":
                  Actions.forms.update(ref);
                  break;

                default:
                  Actions.forms.create(ref);
                  break;
              }
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    deleteForm: (args) => {
      const title = "Delete form?";

      const Body = (
        <p>
          This action:<br></br>- will delete the form{" "}
          <strong>"{args.props.data.title}"</strong>. In the case it is
          available publicly the form will become inactive. This action cannot
          be undone
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.forms.delete(args.props.data._id, args.callback);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
  },
  spaces: {
    editSpace: (args) => {
      let property = {};
      property.configuration = {
        module: "Spaces",
        view: args.view ? args.view : "detail",
        replace: {
          id: args.props?.data._id,
        },
        api: "view",
      };
      const title = args.title ? args.title : "Edit space";

      const ref = React.createRef();

      const Body = (
        <Module property={property} key={$f.key()} ref={ref}></Module>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">{title}</h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.spaces.update(ref);
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    addSpace: (args) => {
      let property = {};
      property.configuration = {
        module: "Spaces",
        view: args.view ? args.view : "detail",
        replace: {},
        api: "new",
        inputData: args.inputData,
      };

      const ref = React.createRef();

      const Body = (
        <>
          <Module property={property} key={$f.key()} ref={ref}></Module>
          <span>{args.subtitle}</span>
        </>
      );

      window["primaryModal"].setState({
        show: true,
        size: "lg",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : "Add space"}
          </h2>
        ),
        footer: [
          args.extraButton,
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.spaces.create(ref);
            }}
            className="btn btn-sm btn-purple"
          >
            Save
          </button>,
        ],
        body: Body,
      });
    },
    deleteSpace: (args) => {
      const title = "Delete group?";
      const spaceType = args.type == "group" ? "group" : "space";

      const Body = (
        <p>
          Are you sure you want to delete {spaceType}{" "}
          <strong>"{args.props.data.name}"</strong>?<p></p>
          This action:<br></br>
          <br></br> - will delete the {spaceType}{" "}
          <strong>"{args.props.data.name}"</strong> along with its users and
          files<br></br>
          <br></br> - will delete all groups under the {spaceType}{" "}
          <strong>"{args.props.data.name}"</strong>, along with their users and
          files
        </p>
      );

      window["primaryModal"].setState({
        show: true,
        size: "default",
        title: (
          <h2 className="display-3-no-line-height header-ellipsis">
            {args.title ? args.title : title}
          </h2>
        ),
        footer: [
          <button
            type="button"
            onClick={() => {
              window["primaryModal"].close();
            }}
            className="btn btn-sm btn-white"
          >
            Cancel
          </button>,
          <button
            type="button"
            onClick={() => {
              Actions.spaces.delete(args.props.data._id, args.callback);
            }}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>,
        ],
        body: Body,
      });
    },
  },
};

export default Modals;
